import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import NavBar from './NavBar';
import { makeTheme } from "./Theme"

import Clubs from './pages/Clubs';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import News from './pages/News';
import NoPage from './pages/NoPage';
import Offers from './pages/Offers';

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function App() {
    const [mode, setMode] = React.useState();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    React.useEffect(() => {
        const existingPreference = localStorage.getItem("theme");
        // Check if we already have a preference.
        if (existingPreference) {
            (existingPreference === "light")
                ? setMode("light")
                : setMode("dark");
        } else {
            if (prefersDarkMode) {
                setMode("dark");
                localStorage.setItem("theme", "dark");
            } else {
                setMode("light");
                localStorage.setItem("theme", "light");
            }
            
        }
    }, [prefersDarkMode]);


    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode(prevMode => {
                    const nextMode = prevMode === "light" ? "dark" : "light";
                    localStorage.setItem("theme", nextMode);
                    return nextMode;
                });
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme(makeTheme(mode)),
        [mode],
    );

    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Routes>
                            <Route path="/"
                                element={
                                    <NavBar
                                        theme={theme}
                                        colorMode={colorMode}
                                    />}>
                                <Route index element={<Home />} />
                                <Route path="vereine" element={<Clubs />} />
                                <Route path="kontakt" element={<Contact />} />
                                <Route path="impressum" element={<Imprint />} />
                                <Route path="aktuelles" element={<News />} />
                                <Route path="angebote" element={<Offers />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
}

export default App;