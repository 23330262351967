import Link from '@mui/material/Link';

const Offers = () => {
    return (
        <>
            <h1>Angebote</h1>
            <p>Um Ultimate Frisbee in der Allgäuer Region (sowohl in Bayern als auch in Baden-Württemberg) zu fördern, bieten wir verschiedene Aktivitäten für Vereine, Gemeinden, Schulen, Firmen usw. an.</p>
            <p>Möglich sind z.B. Workshops und Trainingslager, Kurse oder Schnupperaktivitäten als Rahmenprogramm bei Feierlichkeiten, im gemeindlichen Ferienprogramm, beim Jugendtreff usw.</p>
            <p>Ultimate Frisbee eignet sich auch äußerst gut als Betriebssport oder als Teambuildingsaktivität (besonders in Betracht auf den „Spirit of the Game“).</p>
            <p>Auch Privatpersonen dürfen uns gerne <Link href="/kontakt" underline="none" color="custom.link">kontaktieren.</Link></p>
        </>
    );
};

export default Offers;