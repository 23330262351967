export const makeTheme = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light' ?
            // palette values for light mode
            {
                primary: {
                    main: "#1685CA"
                },
                background: {
                    default: "#2F872C"
                },
                custom: {
                    link: "#1344CC"
                }
            }
            // palette values for dark mode
            : {

                primary: {
                    main: "#1685CA"
                },
                background: {
                    default: "#005500"
                },
                custom: {
                    link: "#4FC3F7"
                }
            }),
    }
});