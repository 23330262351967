import Link from '@mui/material/Link';

import Beitrittsformular from "../documents/Beitrittsformular.pdf"

const Contact = () => {
    return (
        <>
            <h1>Kontakt</h1>
            <p>Anfragen bitte an kontakt(at)ultimateimallgaeu.de</p>
            <p>Ihr findet uns auch auf <Link href="https://www.instagram.com/ultimateimallgaeu/" target="_blank" rel="noopener noreferrer" underline="none" color="custom.link">Instagram</Link></p>
            <p>Für angehende Mitglieder gibt's hier unser <Link href={Beitrittsformular} target="_blank" rel="noopener" underline="none" color="custom.link">Beitrittsformular</Link>.</p>
        </>
    );
};

export default Contact;