import Link from '@mui/material/Link';

import Beitrittsformular from "../documents/Beitrittsformular.pdf"

const Home = () => {
    return (
        <>
            <h1>Willkommen!</h1>
            <p>Ultimate Frisbee ist wohl möglich der beste Sport, von dem (leider) viele im Allgäu noch nie was gehört haben. Das möchten wir ändern! So gründeten wir am 21.05.2019 den Verein Ultimate im Allgäu e.V.</p>
            <p>Wir, das sind – momentan noch – 11 begeisterte Ultimatespieler und -fans der Allgäuer Teams Camp10 (Sulzberg) und MuH (Haldenwang), die sich zusammen geschlossen haben, um die Sportart Ultimate Frisbee im Allgäu voranzutreiben. Wir wollen nicht mehr warten, bis vielleicht alle 5 Jahre mal ein neues Team entsteht! Und unsere Arbeit fruchtet, denn wir konnten mit der Gründung einer Ultimate Frisbee Abteilung in Lachen (nach 2 erfolgreichen von uns begleiteten Workshops erfolgte die Gründung in Oktober 2019) schon einen ersten Erfolg buchen!</p>
            <p>Unsere Ziele sind u.a.:</p>
            <ul>
                <li>
                    <p>Allgäuer Teams und Spieler mit einander verbinden und verknüpfen, sie aktiv unterstützen, vor allem im Rahmen der Öffentlichkeitsarbeit.</p>
                </li>
                <li>
                    <p>In jeder Allgäuer Gemeinde (bei Sportvereinen, Firmen, usw.) Aktivitäten starten, damit möglichst viele Allgäuer diese tolle Sportart kennen lernen.</p>
                </li>
                <li>
                    <p>Bei der Entstehung neuer Teams, wenn erwünscht, treibende Hilfe leisten.</p>
                </li>
            </ul>
            <p>Ihr wollt uns helfen, unsere Ziele zu erreichen? Wir freuen uns über jedes weitere Mitglied in unserem Verein. Mitglieder können aktive Hilfe leisten oder auch als passives Mitglied unsere Ziele unterstützen. Hier geht's zum <Link href={Beitrittsformular} target="_blank" rel="noopener" underline="none" color="custom.link">Beitrittsformular</Link>.</p>
        </>
    );
};

export default Home;