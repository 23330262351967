import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Link from '@mui/material/Link';

const clubs =
    [
        {
            club: "TV Haldenwang",
            teamname: "MuH",
            website: "https://tv-haldenwang.de/abteilungen/ultimate-frisbee/",
            socials: [],
            position: { x: 47.792, y: 10.354 },
            mail: "frisbee@tv-haldenwang.de",
            trainings: [
                {// Winter
                    age: "Kids",
                    time: "Mittwochs 17:00 Uhr - 18:00 Uhr",
                    route: "https://goo.gl/maps/NfNHJoRdtc7yTXqt8"
                },
                {
                    age: "Kids & Jugendliche",
                    time: "Dienstags 18:00 Uhr - 19:30 Uhr",
                    route: "https://goo.gl/maps/NfNHJoRdtc7yTXqt8"
                },
                {
                    age: "Jugendliche & Erwachsene",
                    time: "Dienstags 19:30 Uhr - 21:00 Uhr",
                    route: "https://goo.gl/maps/NfNHJoRdtc7yTXqt8"
                },
                {
                    age: "Freies Spiel",
                    time: "Freitags 20:00 Uhr - 22:00 Uhr",
                    route: "https://goo.gl/maps/NfNHJoRdtc7yTXqt8"
                }
                // { // Sommer
                //     age: "Kids & Jugendliche",
                //     time: "Mittwochs 16:30 Uhr - 18:00 Uhr",
                //     route: "https://goo.gl/maps/EjWhQqgU4xLGFdfJ9"
                // },
                // {
                //     age: "Jugendliche & Erwachsene",
                //     time: "Dienstags 17:30 Uhr - 19:30 Uhr",
                //     route: "https://goo.gl/maps/EjWhQqgU4xLGFdfJ9"
                // },
                // {
                //     age: "Freies Spiel",
                //     time: "Freitags 19:00 Uhr - 21:00 Uhr",
                //     route: "https://goo.gl/maps/FfkhTVPeyWhaT9556"
                // }
            ]
        },
        {
            club: "SV Lachen",
            teamname: "Smileys",
            website: "http://www.sv-lachen.de/ultimate-frisbee",
            socials: [],
            position: { x: 47.939, y: 10.236 },
            mail: "null",
            trainings: [
                { // Winter
                    age: "Jugendliche",
                    time: "Donnerstags 16:30 Uhr - 18:00 Uhr",
                    route: "https://goo.gl/maps/yrdUun3W9r4PhYcRA"
                }
                // { // Sommer
                //     age: "Jugendliche",
                //     time: "Donnerstags 17:00 Uhr - 19:00 Uhr",
                //     route: "https://goo.gl/maps/Gw1nRTvHEJfoy8A96"
                // }
            ]
        },
        {
            club: "TSV Sulzberg",
            teamname: "Camp10",
            website: "https://www.tsv-sulzberg.de/frisbee-1/",
            socials: ["https://www.facebook.com/camp10ultimate/", "https://www.instagram.com/camp10_ultimatefrisbee/"],
            position: { x: 47.661, y: 10.344 },
            mail: "ultimate@tsv-sulzberg.de",
            trainings: [
                { // Winter
                    age: "Erwachsene",
                    time: "Montags 21:00 Uhr - 23:00 Uhr",
                    route: "https://goo.gl/maps/yVRHvQH5wLiYx26ZA"
                }
                // { // Sommer
                //     age: "Erwachsene",
                //     time: "Montags 19:00 Uhr - 21:00 Uhr",
                //     route: "https://goo.gl/maps/yVRHvQH5wLiYx26ZA"
                // }
            ]
        },
        {
            club: "TV Kempten",
            teamname: "Camp10",
            website: "https://www.tvkempten.de/filefrisbee.html",
            socials: ["https://www.facebook.com/camp10ultimate/", "https://www.instagram.com/camp10_ultimatefrisbee/"],
            position: { x: 47.71938130869706, y: 10.290945903307595 },
            mail: "frisbee@tvkempten.de",
            trainings: [
                { // Winter
                    age: "Erwachsene",
                    time: "Montags 18:45 Uhr - 20:00 Uhr",
                    route: "https://maps.app.goo.gl/bUnMZ6j3DnQUYqnC6",
                },
                {
                    age: "Jugendliche",
                    time: "Montags 17:30 Uhr - 18:45 Uhr",
                    route: "https://maps.app.goo.gl/bUnMZ6j3DnQUYqnC6",
                }
                // { // Sommer
                //     age: "Erwachsene",
                //     time: "Dienstag 18:00 Uhr - 20:00 Uhr",
                //     route: "https://goo.gl/maps/domaLLdeLiUzJbYW6",
                // }
            ]
        },
        {
            club: "SG Weißbach",
            teamname: null,
            website: "https://sgw-pfronten.de/frisbee-ultimate/",
            socials: [],
            position: { x: 47.59999, y: 10.55653 },
            trainings: [
                {
                    age: "Erwachsene",
                    time: "Montags 19:00 Uhr - 20:00 Uhr",
                    route: "https://goo.gl/maps/VTNBJbeZ4kPGywAs9",
                }
            ]
        },
        {
            club: "SV Herlatzhofen",
            teamname: null,
            website: "https://www.sv-herlazhofen.de/abteilungen/freizeitsport/ultimate-frisbee/",
            socials: [],
            position: { x: 47.79138, y: 10.01545 },
            trainings: [
                {
                    age: "Jugendliche",
                    time: "Sonntags 15:30 Uhr - 16:30 Uhr",
                    route: "https://maps.app.goo.gl/SLDtaUK3UjZka5Fd8",
                }
            ]
        },
        // {
        //     club: "Fendt",
        //     teamname: "Mitarbeitersport",
        //     website: null,
        //     socials: [],
        //     position: { x: 47.59999, y: 10.55653 },
        //     trainings: [
        //         {
        //             age: "Erwachsene",
        //             time: null,
        //             route: null,
        //         }
        //     ]
        // },
        // {
        //     club: "Lindenberg",
        //     teamname: null,
        //     website: null,
        //     socials: [],
        //     position: { x: 47.59999, y: 10.55653 },
        //     trainings: [
        //         {
        //             age: "Erwachsene",
        //             time: null,
        //             route: null,
        //         }
        //     ]
        // },
    ];

const Clubs = () => {
    return (
        <>
            <h1>Trainingszeiten der Vereine</h1>

            {clubs.map((club) => (
                <p>
                    <b>{club.club}{club.teamname && <>{' (' + club.teamname + ')'}</>}:</b>
                    <Link href={club.website} margin="1rem" target="_blank" rel="noopener noreferrer" underline="none" color="custom.link">Website</Link>
                    {club.mail && <Link href={"mailto:" + club.mail} target="_blank" rel="noopener noreferrer" underline="none" color="custom.link">Kontakt</Link>}
                </p>
            ))}

            <div id="map">
                <MapContainer center={[47.80, 10.33]} zoom={9} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {clubs.map((club) => (
                        <Marker position={[club.position.x, club.position.y]}>
                            <Popup>
                                <h3>Training(s) beim {club.club}:</h3>
                                {club.trainings.map((training) => (
                                    <li>{training.age}: {training.time} <Link href={training.route} target="_blank" rel="noopener noreferrer" underline="none" color="custom.link">hier</Link></li>
                                ))}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>


        </>
    );
};

export default Clubs;