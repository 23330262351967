import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

// PDFs
import TVK_Frisbee_Flyer from "../documents/TVK_Frisbee_Flyer.pdf"
import Beitrittsformular from "../documents/Beitrittsformular.pdf"

// Images
import Workshop_SSV_Markt_Rettenbach from "../images/2020-10-02_Workshop_SSV_Markt_Rettenbach.jpg"
import Vermittlung_Frisbee_Mittelschule_Dietmannsried from "../images/2020-02-07_Vermittlung_Frisbee_Mittelschule_Dietmannsried.jpg"

const News = () => {
    return (
        <>
            <h1 className="ArticleHeader">Workshop beim SSV Markt Rettenbach</h1>
            <h3 className="ArticleDate">02.10.2020</h3>
            <p>Einer bunten Mischung aus Erwachsenen, Jugendlichen und Kindern wurden Grundzüge der Sportart gezeigt und vor allem Spaß und Leistungsherausforderung beim Ultimate Frisbee beigebracht. Beim SSV wird nun geprüft, in wiefern Ultimate Frisbee ein etwas festerer Bestandteil des Angebots sein könnte.</p>
            <Box
                component="img"
                sx={{
                    width: '100%',
                }}
                alt="Workshop SSV Markt Rettenbach"
                src={Workshop_SSV_Markt_Rettenbach}
            />



            <h1 className="ArticleHeader">Anfängerkurs beim TV Kempten</h1>
            <h3 className="ArticleDate">24.06.2020</h3>
            <p>Hier der <Link href={TVK_Frisbee_Flyer} target="_blank" rel="noopener" underline="none" color="custom.link">Flyer</Link> zum Anfängerkurs beim TV Kempten</p>



            <h1 className="ArticleHeader">Projekt: Vermittlung Ultimate Frisbee in der Mittelschule Dietmannsried</h1>
            <h3 className="ArticleDate">07.02.2020</h3>
            <p>Am 29.01. fand im Rahmen des Tages der offenen Tür der Mittelschule Dietmannsried nach 2 Unterrichtseinheiten Ultimate Frisbee während des regulären Sportunterrichts ein kleines Turnier für die teilnehmenden Kinder statt. Hier konnten sie Gelerntes in einer realen Turnier- und Wettkampfsituation umsetzen und ihr Können mit dem Frisbee zeigen. Am Schluss bekamen alle Kinder eine Urkunde. Manche erwiesen sich als echte Talente und wir hoffen hier und dort eine kleine Leidenschaft für die fliegende Scheibe erweckt zu haben.</p>
            <p>Neben Wurftechniken, Grundregelkenntnisse und ein wenig Taktik legten wir in den beiden Unterrichtseinheiten auch viel Wert auf den Spirit of the Game und natürlich stand überhaupt der Spaß an der Bewegung im Vordergrund. Gerne kommen wir auch in Ihrer Schule vorbei!</p>
            <Box
                component="img"
                sx={{
                    width: '100%',
                }}
                alt="Vermittlung Frisbee Mittelschule Dietmannsried"
                src={Vermittlung_Frisbee_Mittelschule_Dietmannsried}
            />



            <h1 className="ArticleHeader">Vermittlung Ultimate Frisbee im Sportunterricht an der Mittelschule Dietmannsried</h1>
            <h3 className="ArticleDate">09.01.2020</h3>
            <p>Im Januar 2020 werden wir 2x die 7. Klasse der Mittelschule Dietmannsried besuchen, um dort die Grundtechniken und Basisregeln zu vermitteln.</p>



            <h1 className="ArticleHeader">Neues Beitrittsformular</h1>
            <h3 className="ArticleDate">22.11.2019</h3>
            <p>Wir haben ein neues <Link href={Beitrittsformular} target="_blank" rel="noopener" underline="none" color="custom.link">Beitrittsformular</Link>. Es steht ab sofort auf unserer Seite zur Verfügung.</p>



            <h1 className="ArticleHeader">Neue Abteilung beim SV Lachen</h1>
            <h3 className="ArticleDate">14.11.2019</h3>
            <p>In 2019 hielten wir zwei Workshops beim SV-Lachen ab (im Juli und Oktober). Diese fanden großen Zuspruch und Nachfrage. Aufgrund dessen wurde nun auch eine Abteilung Ultimate Frisbee beim SV Lachen gegründet. Das neue Team aus Lachen, die Smileys, nahm schon am Outdoor Allgäu Cup 2019 in Lindenberg teil. Auch waren Kinder und Jugendliche der Smileys bei Trainingstagen in Haldenwang und Augsburg und unterstützten die Haldenwanger Kinder und Jugendlichen beim „Frisbee'schen Weihnachten“Turnier.</p>
        </>
    );
};

export default News;